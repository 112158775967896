<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="true"
			:externalFilter="externalFilter"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="true"
			:modalDialog="modalDialog"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<cargo-modals :modelName="modelName"></cargo-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import cargoActions from './CargoActions';
import cargoModal from './CargoModals.vue';

export default {
	name: 'cargo-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'cargo-modals': cargoModal
	},
	data() {
		return {
			model: 'cargo',
			modelName: 'cargo',
			actions: cargoActions.gridactions,
			modelColumnDefs: {
				unloadingstatus: this.generateCellStyle(),
				loadingstatus: this.generateCellStyle()
			}
		};
	},
	methods: {
		generateCellStyle() {
			return {
				render: (data) => {
					let style = '';
					switch (data) {
						case 'Cleared': //Green
						case 'ნებართვა გაცემულია': //Green
							style = 'background-color: #24a54e9e;';
							break;
						case 'Rejected': //Red
						case 'ნებართვა უარყოფილია': //Red
							style = 'background-color: #e8624d;';
							break;
						case 'Unallocated': //Red
						case 'ნომინირების გარეშე': //Red
							style = 'background-color: #e8624d;';
							break;
						case 'Documentation rejected':
						case 'დოკუმენტაცია უარყოფილია':
							style = 'background-color: #e8624d;';
							break;
						case 'Documentation pending': //Orange
						case 'დოკუმენტაცია მოლოდინის რეჟიმშია': //Orange
							style = 'background-color: #e9711aa1;';
							break;
						case 'Documentation ready for review': //Yellow
						case 'დოკუმენტაცია მზად არის განსახილველად': //Yellow
							style = 'background-color: #e9db1aa1;';
							break;
						case 'Partially cleared':
						case 'ნაწილობრივი ნებართვა გაცემულია':
							style = 'background-color: #e9db1aa1;';
							break;
						case 'Documentation validated':
						case 'დოკუმენტაცია დადასტურებულია':
							style = 'background-color: #e9db1aa1;';
							break;
						case 'Clearance requested':
						case 'მოთხოვნა ნებართვაზე გაგზავნილია':
							style = 'background-color: #e9db1aa1;';
							break;
						default:
							break;
					}
					return `<div style="${style} font-weight: bold; padding: 10px; border-radius: 10px">${data}</div>`;
				}
			};
		}
	}
};
</script>
